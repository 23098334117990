
























































































.select-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.inner-content {
    margin: 20px;
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.big-title {
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.select-box {
    height: 1%;
    flex: 1;
}

.table-index {
    font-size: 14px;
}

.good-info {
    display: flex;
    align-items: center;

    .good-img {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .good-title {
        margin-left: 10px;
        width: 1%;
        flex: 1;

        .text-overflow {
            color: #fff;
        }
    }
}

.see-btn {
    display: inline-block;
    padding: 10px;
    color: #2821fc;
    font-weight: 500;
    white-space: nowrap;

    &:hover {
        color: #534dfd;
    }
}

.el-table {
    position: absolute;
    width: 100%;

    &:before {
        content: none;
    }

    ::v-deep .el-table__empty-block {
        background: #070932;

        .el-table__empty-text {
            color: #fff;
        }
    }

    ::v-deep .el-table__body-wrapper {
        background: #070932;
    }


    ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none;
    }

    ::v-deep td.el-table__cell {
        border-bottom: 1px solid #262963;

        &:first-child {
            border-left: 1px solid #262963;
        }

        &:last-child {
            border-right: 1px solid #262963;
        }
    }
}
